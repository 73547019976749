<template>
<div> 
  <v-data-table
    :headers="headers"
    :items="campanas"
    sort-by="calories"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Campañas</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-autocomplete
              v-model="modelOrigen"
              class="mr-3"
              style="margin-top: 1.5em;"
              :items="itemsOrigen"  
               
              color="white"
              hide-no-data
              hide-selected
              item-text="label"
              item-value="id"
              label="Origen de campaña"
              placeholder="Asistente"
              prepend-icon="mdi-robot"
              return-object
              @change="seleccionAsistente"
            ></v-autocomplete>
              <v-divider
                class="mx-4"
                inset
                vertical
              ></v-divider>
        <v-spacer></v-spacer>
        <v-autocomplete
              v-model="modelStatus"
              class="mr-3"
              style="margin-top: 1.5em;"
              :items="itemsStatus"  
              color="white"
              hide-no-data
              hide-selected
              item-text="label"
              item-value="status"
              label="Estatus de campaña"
              return-object
              @change="seleccionAsistente"
            ></v-autocomplete>
        <v-autocomplete
              v-model="modelCant"
              class="mr-2"
              style="margin-top: 1.5em;"
              :items="itemsCant"  
              color="white"
              hide-no-data
              hide-selected
              item-text="label"
              item-value="cant"
              label="Cantidad"
              return-object
              @change="seleccionAsistente"
            ></v-autocomplete>
    <v-dialog
      v-model="dialogCampana"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="success"
          dark
          v-bind="attrs"
          v-on="on"
          :loading="isLoading"
        >
         Nueva campaña
        </v-btn>
      </template>
      <v-card>
        <v-toolbar
          dark
          color="success"
        >
          <v-btn
            icon
            dark
             @click="cancelarGurdadoCampana()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Nueva campaña</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
               @click="cancelarGurdadoCampana()"
            >
              Salir
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
<template>
  <v-stepper v-model="e1">
    <v-stepper-header>
      <v-stepper-step
        :complete="e1 > 1"
        step="1"
      >
       Datos de campaña
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
        :complete="e1 > 2"
        step="2"
      >
       Folio Generico
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="3">
        Folios Estudios
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <v-card
          class="mb-12" 
        >
         <v-row> 
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  > 
                    <v-text-field
                      v-model="editedItem.nombre"
                      label="Nombre"
                    ></v-text-field>
                  </v-col>
                   <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
            <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="dateInicio"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="dateInicio"
                    label="Inicio Vigencia"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
                </template>
                <v-date-picker
                v-model="dateInicio"
                no-title
                scrollable
                >
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="menu = false"
                >
                    Cancel
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(dateInicio)"
                >
                    OK
                </v-btn>
        </v-date-picker>
      </v-menu>
                    
                  </v-col>
                 <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
            <v-menu
            ref="menuFin"
            v-model="menuFin"
            :close-on-content-click="false"
            :return-value.sync="dateFin"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="dateFin"
                    label="Inicio Vigencia"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
                </template>
                <v-date-picker
                v-model="dateFin"
                no-title
                scrollable
                >
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="menuFin = false"
                >
                    Cancel
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="$refs.menuFin.save(dateFin)"
                >
                    OK
                </v-btn>
        </v-date-picker>
      </v-menu> 
    </v-col> 
    </v-row>
     <v-row
     justify="center"
     > 

         <v-col
            cols="12"
            sm="6"
            md="4"
            > 
        <v-text-field
            v-model="editedItem.cantidad"
            label="Folios a Entregar"
        ></v-text-field>
        </v-col>
        
     </v-row>
        <v-row
        justify="center"
        >
            <v-switch
                v-model="camapanaConBotones"
                label="Incluir botones?"
                color="orange darken-3"
                hide-details
              ></v-switch>
            
          </v-row>
          <v-row
          justify="center"
          >
           <!--BOTONES CAMPAÑA-->
            <v-card
    class="mx-auto"
    max-width="500"
    outlined
    transition="scale-transition"
    v-show = 'camapanaConBotones'
  >
    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline mb-6">
          Botones para campaña
        </div>
         <v-col
            cols="12" 
            > 
        <v-text-field
            v-model="btnCampana"
            label="Contenido de los botón"
        >
        </v-text-field>
        <v-btn
          color="success" 
          @click="addBotonCampana()"
        >
          Agregar
        </v-btn>
        </v-col>
      </v-list-item-content>

       
    </v-list-item>
 <v-card
    class="mx-auto"
    max-width="500"
  >
    <v-list>
      <v-list-item-group v-model=" selectedItem">
        <v-list-item
          v-for="(bnt, i) in botonesCampana"
          :key="i"
        >
          <v-list-item-icon>
           <v-icon @click="deleteThisButon(i)">mdi-close</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="bnt"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
    
  </v-card>

  <!--BOTONES CAMPAÑA-->
          </v-row>
     
                   <v-row> 
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-textarea
                      v-model="editedItem.copy_informacion"
                      label="Copy Informacion" 
                      filled
                    ></v-textarea>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-textarea
                      v-model="editedItem.copy_entrega"
                      label="Copy Entrega" 
                      filled
                    ></v-textarea>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-textarea
                      v-model="editedItem.copy_instrucciones"
                      label="Copy Bases" 
                      filled
                    ></v-textarea>
                  </v-col>

                 
                </v-row>
         <v-alert
        dismissible
        type="success"
        v-show="alertaPaso1"
        >
        {{TextAlertaP1}}
        </v-alert>
        </v-card> 
        <v-btn
          color="success"
          :loading="isLoading"
          @click="pasoUno()"
        >
          Continuar
        </v-btn>

        <v-btn text
        @click="cancelarGurdadoCampana()"
        >
          Cancelar
        </v-btn>
      </v-stepper-content>

      <v-stepper-content step="2">
        <v-card
          class="mb-12" 
        >
        <v-card>
            <v-row
            justify="center"
            > 
            <v-card-title >
              <span  class="text-h5" justify="center">Codigo Generico de campaña : SDCODIGO2022</span>
            </v-card-title>
            </v-row>
            <v-card-text>
              <v-container>
                <v-row justify="center">  

                  <v-col
                    cols="8"
                    sm="8"
                    md="8"
                  > 
                    <v-text-field
                      v-model="editedItem.nombre"
                      label="Nombre Campaña"
                      disabled
                    ></v-text-field>
                  </v-col>

                   <v-col
                    cols="8"
                    sm="8"
                    md="8"
                  > 
                    <v-text-field
                      v-model="campanaCodGenNuevaPaso2"
                      label="Codigo Generico" 
                    ></v-text-field>
                  </v-col>
                
    </v-row>
                   
              </v-container>
            </v-card-text>
                
          </v-card>
        </v-card>
        <v-btn text
         @click="e1=1"
        >
          Volver
        </v-btn>

        <v-btn
          color="success"
          @click="pasoDos()"
        >
          Continuar
        </v-btn>

        <v-btn text
        @click="cancelarGurdadoCampana()"
        >
          Cancelar
        </v-btn>
      </v-stepper-content>

      <v-stepper-content step="3">
        <v-card
          class="mb-12" 
        >
        <v-row justify='center'>
             <v-card-title >
            <span  class="text-h5" justify="center">Cargar Folios de estudios</span>
        </v-card-title>
        </v-row>
          <v-row justify='center'>  
       
                  <v-col
                    cols="8"
                    sm="8"
                    md="8"
                  > 
                    <v-text-field
                      v-model="editedItem.nombre"
                      label="Nombre Campaña"
                      disabled
                    ></v-text-field>
                  </v-col>

                   <v-col
                    cols="8"
                    sm="8"
                    md="8"
                  > 
                     <v-file-input
                        v-model="filesFolios"
                        truncate-length="1" 
                        :label="modelexcell==null?'Adjuntar excel con folios':'Adjuntar excel con folios ' "
                        show-size
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        prepend-icon="mdi-file-excel"
                        :disabled="model==null" 
                        ></v-file-input>
                  </v-col>
                
    </v-row>
        
        </v-card>
        <v-btn text
                @click="e1=2"
                >
          Volver
        </v-btn>
        <v-btn
          color="primary"
          :loading="isLoading"
          @click="pasoTres()"
        >
          Finalizar
        </v-btn>

        <v-btn text
         @click="cancelarGurdadoCampana()"
        >
          Cancelar
        </v-btn>
      </v-stepper-content>
    </v-stepper-items>
    <v-snackbar
      :color="alertaTypo"
      v-model="alerta"
      :multi-line="true"
      :timeout="5000"
      centered
      outlined
      
    >
      {{ textAlert }}

      <template   >
        <v-btn 
        :color="alertaTypo"
          text  
          elevation="24"
           @click="alerta = false"
        >
          OK
        </v-btn>
      </template>
    </v-snackbar>
  </v-stepper>
   
</template>
      </v-card>
    </v-dialog> 
         <!--inicio dialog campaña-->
        <v-dialog
          v-model="dialog"
          max-width="900px"
          persistent
        > 
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row> 
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  > 
                    <v-text-field
                      v-model="editedItem.nombre"
                      label="Nombre"
                    ></v-text-field>
                  </v-col>
                   <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
            <v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            :return-value.sync="dateInicio2"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="dateInicio2"
                    label="Inicio Vigencia"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
                </template>
                <v-date-picker
                v-model="dateInicio2"
                no-title
                scrollable
                >
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="menu2 = false"
                >
                    Cancel
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="$refs.menu2.save(dateInicio2)"
                >
                    OK
                </v-btn>
        </v-date-picker>
      </v-menu>
                    
                  </v-col>
                 <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
            <v-menu
            ref="menuFin2"
            v-model="menuFin2"
            :close-on-content-click="false"
            :return-value.sync="dateFin2"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="dateFin2"
                    label="Inicio Vigencia"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
                </template>
                <v-date-picker
                v-model="dateFin2"
                no-title
                scrollable
                >
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="menuFin2 = false"
                >
                    Cancel
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="$refs.menuFin2.save(dateFin2)"
                >
                    OK
                </v-btn>
        </v-date-picker>
      </v-menu> 
    </v-col> 
    </v-row>
                   <v-row> 
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-textarea
                      v-model="editedItem.copy_informacion"
                      label="Copy Informacion" 
                      filled
                    ></v-textarea>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-textarea
                      v-model="editedItem.copy_entrega"
                      label="Copy Entrega" 
                      filled
                    ></v-textarea>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-textarea
                      v-model="editedItem.copy_instrucciones"
                      label="Copy Bases" 
                      filled
                    ></v-textarea>
                  </v-col>

                 
                </v-row>
              </v-container>
            </v-card-text>
                <v-alert
                dismissible
                type="success"
                v-show="ocultarAlerta"
                >
                Elementos guardados correctamente.
                </v-alert>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save()"
              >
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog >
         <!--OTRO MODAL-->
         <v-dialog
          v-model="dialogCodigoGenerico"
          max-width="600px"
          persistent
        >
           
          <v-card>
            <v-card-title>
              <span class="text-h5">Codigo Generico de campaña : SDCODIGO2022</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row> 
                  <v-col
                    cols="4"
                    sm="4"
                    md="4"
                  > 
                    <v-text-field
                      v-model="campanaIdCodGen"
                      label="Id"
                      disabled
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="8"
                    sm="8"
                    md="8"
                  > 
                    <v-text-field
                      v-model="campanaNombreCodGen"
                      label="Nombre"
                      disabled
                    ></v-text-field>
                  </v-col>

                   <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  > 
                    <v-text-field
                      v-model="codigoGenericoActual"
                      label="Codigo Generico" 
                    ></v-text-field>
                  </v-col>
                
    </v-row>
                   
              </v-container>
            </v-card-text>
                <v-alert
                dismissible
                type="success"
                v-show="ocultarAlertaFolios"
                >
                Elementos guardados correctamente.
                </v-alert>
                 <v-alert
                dismissible
                type="error"
                v-show="ocultarAlertaFoliosError"
                >
                Ocurrio un error intentar de nuevo.
                </v-alert>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="cerrarModalCodGen()"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="guardarCodigoGenerico()"
                :loading="isLoading"
              >
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
         <!--OTRO MODAL-->

          <!--OTRO MODAL2-->
         <v-dialog
          v-model="dialogFolios"
          max-width="600px"
          persistent
        >
           
          <v-card>
            <v-card-title>
              <span class="text-h5">Asignacion de folios de estudios.</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row> 
                  <v-col
                    cols="4"
                    sm="4"
                    md="4"
                  > 
                    <v-text-field
                      v-model="campanaIdCodGen"
                      label="Id"
                      disabled
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="8"
                    sm="8"
                    md="8"
                  > 
                    <v-text-field
                      v-model="campanaNombreCodGen"
                      label="Nombre"
                      disabled
                    ></v-text-field>
                  </v-col>

                   <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  > 
                     <v-file-input
                        v-model="files"
                        truncate-length="1" 
                        :label="modelexcell==null?'Adjuntar excel con folios':'Adjuntar excel con folios ' "
                        show-size
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        prepend-icon="mdi-file-excel"
                        :disabled="model==null" 
                        ></v-file-input>
                  </v-col>
                
    </v-row>
                   
              </v-container>
            </v-card-text>
               
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="cerrarModalFolios()"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="guardarFolios()"
                :loading="isLoading"
              >
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
         <!--OTRO MODAL2-->
        <!-- MODAL FOLIOS-->  
    <v-dialog
      v-model="dialogFoliosCampana"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    > 
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialogFoliosCampana = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Informacion Campaña</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
            style="background-color: green; color: white;" 
              text 
              @click="exportToExcel"
            >
              Exportar folios
            </v-btn>
            <v-btn
              dark
              text
              @click="dialogFoliosCampana = false"
            >
              Cerrar
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
       <template>

        
  <v-data-table
    :headers="headersFolios"
    :items="itemFoliosCampana"
    sort-by="calories"
    class="elevation-1"
  >
  
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItemFolio(item)"
      >
        mdi-pencil
      </v-icon>
       
    </template>
    
  </v-data-table>
</template>
      </v-card>
    </v-dialog> 
        <!--FIN MODAL FOLIOS-->

      </v-toolbar>
    </template>
    
    <template v-slot:item.actions="{ item }">

    <v-col
      cols="12"
      sm="6" 
    >  
      <v-btn-toggle  >
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
            <v-btn @click="editItem(item)" 
            small
            v-bind="attrs"
             v-on="on">
            <v-icon> mdi-pencil</v-icon>
            </v-btn> 
        </template>
        <span>Editar Campaña</span>
      </v-tooltip>
        
        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
            <v-btn @click="addCodigoGenerico(item)"
                small
                v-bind="attrs"
                v-on="on">
                <v-icon> mdi-sort-alphabetical-descending-variant</v-icon>
            </v-btn>
            </template>
          <span>Editar Codigo</span>
        </v-tooltip>
        
        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <v-btn @click="addFoliosEstudios(item)"
                small
                v-bind="attrs"
                v-on="on">
                    <v-icon>mdi-page-next-outline</v-icon>
                </v-btn> 
            </template>
            <span>Agregar Folios</span>
        </v-tooltip>
        
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <v-btn @click="verFolios(item)"
                small
                v-bind="attrs"
                v-on="on">
                    <v-icon>mdi-archive-eye-outline</v-icon>
                </v-btn> 
            </template>
            <span>Ver Folios</span>
        </v-tooltip>

        <!--v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <v-btn @click="simularEnvio(item)"
                small
                v-bind="attrs"
                v-on="on">
                    <v-icon>mdi-share</v-icon>
                </v-btn> 
            </template>
            <span>Simular envio</span>
        </v-tooltip-->

      </v-btn-toggle>
    </v-col>
     
       <v-snackbar
      :color="alertaModalsColor"
      v-model="alertaModals"
      :multi-line="true"
      :timeout="5000"
      centered
      outlined
      
    >
      {{ textAlertModals }}

      <template   >
        <v-btn 
        :color="alertaModalsColor"
          text  
          elevation="24"
           @click="alertaModals = false"
        >
          OK
        </v-btn>
      </template>
    </v-snackbar>
    </template> 
  </v-data-table>  

  
   </div>
   
</template> 
<script>   
import Servicio from '@/service/apis'
import moment from 'moment'    
import * as XLSX from 'xlsx';
export default {
  name:'Campanas',
  data: () => ({
     dateInicio: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), 
     dateFin : (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), 
     dateInicio2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), 
     dateFin2 : (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), 
    show: false,
    modelOrigen:{id:1,label:'Whatsapp Mexico'},
    itemsOrigen :[{id:1,label:'Whatsapp Mexico'},{id:2,label:'Whatsapp Hemodialisis'} ,{id:5,label:'Whatsapp CAM'}  ],
    modelStatus:{status:2,label:'Todos'},
    itemsStatus :[{status:1,label:'Activos'},{status:0,label:'Finalizados'} ,{status:2,label:'Todos'}  ],
    modelCant:{cant:50,label:'50'},
    itemsCant :[{cant:50,label:'50'},{cant:100,label:'100'} ,{cant:150,label:'150'},{cant:200,label:'200'},{cant:300,label:'300'},{cant:0,label:'Todo'}  ],
    nivel: 1, 
    sesion:JSON.parse(sessionStorage.getItem('sesion')),
    excel:{},
    model:{nombre_hsm:null}, 
    modelexcell:null,
    menu:false,
    menu2:false,
    botonesCampana : [],
    alertaModalsColor : '',
    alertaModals : false,
    textAlertModals : '',
    ocultarAlerta :false,
    e1: 1,
    menuFin:false,
    menuFin2:false,
    envio:{descripcion:null},
     selectedItem: 1,
    dateplaceholder: new Date().toISOString(),
    fecha:null,
    isLoading:false, 
    error:false,
    respuesta:null,
    campanas:[],
    itemFoliosCampana:[],
    dialog: false,
    files:null,
    filesFolios:null,
    dialogCampana : false ,
    dialogCodigoGenerico: false,
    dialogDelete: false,
    dialogFolios:false,
    enviado:true,
    ocultarAlertaFolios :false,
    campanaIdCodGen: '',
    campanaNombreCodGen:'',
    codigoGenericoActual:'',
    campanaCodGenNuevaPaso2:'',
    ocultarAlertaFoliosError:false,
    alertaPaso1 : false,
    alerta:false,
    btnCampana : '',
    TextAlertaP1 : '',
    camapanaConBotones :false,
    textAlert:'',
    enviadoPaso1:false,
    alertaCampanas : false,
    alertaTypo : 'success',
    campanasType:'',
    dialogFoliosCampana:false,
    headers: [
          {
            text: 'Id',
            align: 'start',
            sortable: false,
            value: 'id',
          },
          { text: 'Nombre', value: 'nombre' },
          { text: 'Copy Info', value: 'copy_informacion' },
          { text: 'Copy Entrega', value: 'copy_entrega' },
          { text: 'Copy Bases', value: 'copy_instrucciones' },
          { text: 'Vigencia Inicio', value: 'vigencia_inicio' },
           { text: 'Vigencia Fin', value: 'vigencia_fin' },
           { text: 'Estatus', value: 'estatus' },
           { text: 'Entregados', value: 'entregados' },
           { text: 'Libres', value: 'libres' },
           { text: 'Acciones', value: 'actions' },
        ],
     headersFolios: [
          {
            text: 'Id',
            align: 'start',
            sortable: false,
            value: 'id',
          },
          { text: 'Folio', value: 'folio' },
          { text: 'Telefono', value: 'senderid' },
          { text: 'Estatus', value: 'status' },
          { text: 'Creado', value: 'createdAt' },
          { text: 'Ultima Actualizacion', value: 'updatedAt' },
           { text: 'Codigo Cliente', value: 'codigo_cliente' },
           { text: 'Capturista', value: 'nombre' },
           //{ text: 'Acciones', value: 'actions' },
        ],
    editedIndex: -1,
      editedItem: {
        id:'',
        nombre: '',
        copy_informacion: '',
        copy_entrega: '',
        copy_instrucciones: '',
        vigencia_inicio: '',
        vigencia_fin: '',
        estatus: '',
        cantidad : '',
        capturista_id : ''
      },
      defaultItem: {
        id:'',
        nombre: '',
        copy_informacion: '',
        copy_entrega: '',
        copy_instrucciones: '',
        vigencia_inicio: '',
        vigencia_fin: '',
        estatus: '', 
        cantidad : '',
        capturista_id : ''
      },
      totalCampanas:0,
  }),
  computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nueva Campaña' : 'Editar Campaña'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

  components: {    
  },
  mounted(){
     this.cargarCampanas()
  },
  methods:{
    
    cargarCampanas(){
      let origen = [this.modelOrigen.id, this.modelCant.cant]

     this.isLoading=true
     Servicio.getCampanas(origen).then((resp)=>{ 
        resp.result.forEach(element => {

            element.vigencia_inicio = (new Date(moment(element.vigencia_inicio ).format('YYYY-MM-DD')).toISOString().substr(0, 10))
            element.vigencia_fin = (new Date(moment(element.vigencia_fin ).format('YYYY-MM-DD')).toISOString().substr(0, 10))
            if(element.status==1){
                element.estatus = 'ACTIVA'
              
            }else{
                element.estatus = 'FINALIZADO'
            }
        })
        if(this.modelStatus.status==1){
          this.campanas = resp.result.filter(e=>e.status==1)
          this.isLoading=false 
        }else if(this.modelStatus.status==0){
          this.campanas = resp.result.filter(e=>e.status==0)
          this.isLoading=false
        }else{
          this.campanas = resp.result
          this.isLoading=false
        }
      })
    }, 
    editItem (item) {  
        this.ocultarAlerta=false 
        this.dateInicio2 =(new Date(moment(item.vigencia_inicio ).format('YYYY-MM-DD')).toISOString().substr(0, 10))
        this.dateFin2 =(new Date(moment(item.vigencia_fin ).format('YYYY-MM-DD')).toISOString().substr(0, 10))
        this.editedIndex = this.campanas.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },
    cerrarModalFolios(){
        this.dialogFolios = false
    },
    exportToExcel() {
      const ws = XLSX.utils.json_to_sheet(this.itemFoliosCampana);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
      XLSX.writeFile(wb, 'folios.xlsx');
    },
    addCodigoGenerico (item) { 
        this.ocultarAlertaFolios = false
        this.ocultarAlertaFoliosError = false
        this.dialogCodigoGenerico = true
        this.campanaIdCodGen =item.id
        this.campanaNombreCodGen = item.nombre 

        Servicio.getFolioGenericoCampana(item.id,this.modelOrigen.id).then((resp)=>{ 
            resp.result  
            this.codigoGenericoActual = resp.result.folio
        }).finally(()=>{
            this.isLoading=false
        })

      }, 
      cerrarModalCodGen(){
        this.dialogCodigoGenerico = false
        this.campanaIdCodGen = ''
        this.campanaNombreCodGen = ''
        this.codigoGenericoActual=''
      },
      guardarCodigoGenerico(){
        this.isLoading = true  
              Servicio.guardarFolioGenerico({campana_id: this.campanaIdCodGen,folio: this.codigoGenericoActual,capturista_id : this.sesion.id, origen :this.modelOrigen.id }).then((resp)=>{ 
                    if(resp.status){
                        this.ocultarAlertaFolios = true
                    }else{
                        this.ocultarAlertaFoliosError = true
                    }
               }).finally(()=>{
                    this.isLoading=false
                    this.dialogCodigoGenerico = false
                })
      },
      addFoliosEstudios(item){
        this.dialogFolios = true
        this.campanaIdCodGen =item.id
        this.campanaNombreCodGen = item.nombre 
      },
      pasoUno(){   
 
             this.editedItem.vigencia_inicio = this.dateInicio
             this.editedItem.vigencia_fin =this.dateFin
             console.log(this.editedItem)
       if(this.editedItem.nombre.trim().length <= 0 || this.editedItem.copy_entrega.trim().length <= 0 || this.editedItem.copy_instrucciones.trim().length <= 0
       ||   this.editedItem.vigencia_inicio.trim().length <= 0 || this.editedItem.vigencia_fin.trim().length <= 0 || Number(this.editedItem.cantidad.trim().length) <= 0 ){
             this.mensajeAlerta('error','Es necesario llenar todos los campos para poder avanzar')
       }else{  

        if(this.camapanaConBotones  ){
            if(this.botonesCampana.length>=1){
                this.e1 = 2  
            }else{
                this.mensajeAlerta('error','No se espesificaron botones para la campaña.')
            }

        }else{
            this.e1 = 2  
        } 
        
       }
        
      }, 
       pasoDos(){
         this.isLoading=true
        console.log(this.editedItem)
       if(this.campanaCodGenNuevaPaso2 .trim().length <= 0){
            this.mensajeAlerta('error','El codigo generico no puede estar vacio!!')
       }else{ 
         this.isLoading=false
         this.e1 = 3
       }
        
      }, 
       pasoTres(){ 
         this.editedItem.vigencia_inicio = this.dateInicio
         this.editedItem.vigencia_fin =this.dateFin
         this.editedItem.origen = this.modelOrigen.id
        this.isLoading=true  
            this.editedItem.capturista_id = this.sesion.id

            if(this.camapanaConBotones){
              this.editedItem.botones = true 
              this.editedItem.arrayBotones = this.botonesCampana
            }   
            this.editedItem.foliogenerico =  this.campanaCodGenNuevaPaso2 
            console.log(this.editedItem.foliogenerico )
            Servicio.nuevaCampana(this.editedItem).then((resp)=>{    
                if(resp.status){
                    this.editedItem.id =resp.result.id

                    Servicio.guardarFolioGenerico({campana_id: resp.result.id,folio: this.campanaCodGenNuevaPaso2 ,capturista_id :this.sesion.id, origen :this.modelOrigen.id }).then((recg)=>{ 
                            if(recg.status){

                                 let formData = new FormData()
                                formData.append("files", this.filesFolios)
                                formData.append("capturista_id",this.sesion.id)
                                formData.append("campana_id", this.editedItem.id)    
                                formData.append("origen",this.modelOrigen.id)    

                           if(this.filesFolios){
                                Servicio.guardarFolioEstudiosExcel(formData).then((repfl)=>{ 

                                       if(repfl.status){

                                        if(repfl.codigo==2){
                                            this.mensajeAlerta('success','Los elementos se guardaron correctamente.')   
                                        }else{
                                            this.mensajeAlerta('success','Los elementos se guardaron correctamente.')   
                                            this.cancelarGurdadoCampana()
                                        }
                                           
                                       }else{
                                            this.mensajeAlerta('error','Ocurrio un error al guardar los folios, podras seguir con el registro desde la tabla de campañas.')  
                                            this.cancelarGurdadoCampana()
                                       }
                                }).finally(()=>{
                                    this.isLoading=false
                                }) 
                                }else{
                                   this.isLoading=false
                                    this.mensajeAlerta('success','Los elementos se guardaron correctamente.')   
                                    this.cancelarGurdadoCampana()
                                }


                            }else{
                                 this.mensajeAlerta('error','Ocurrio un error al guardar el codigo generico, podras seguir con el registro desde la tabla de campañas.')  
                                 this.isLoading=false 
                            }
                    })

                }else{

                    this.mensajeAlerta('error','Ocurrio un error al intentar guardar los datos, favor de revisar la informacion')
                    this.isLoading=false 
                }

            }).finally(()=>{
                this.isLoading=false
                this.cargarCampanas()
            })  
      }, 
      mensajeAlerta(tipo,texto){
            this.alertaTypo = tipo
            this.textAlert = texto
            this.alerta =true
      },
      mensajeAlertaModals(tipo,texto){
        this.alertaModalsColor =tipo
        this.alertaModals =true
        this.textAlertModals =texto
      },
      guardarFolios(){ 
      this.isLoading=true

      if(this.files){ 
          let formData = new FormData()
          formData.append("files", this.files)
          formData.append("capturista_id",this.sesion.id)
          formData.append("campana_id", this.campanaIdCodGen)    
          formData.append("origen",this.modelOrigen.id)  
          
           Servicio.guardarFolioEstudiosExcel(formData).then((resp)=>{  
                  console.log(resp)
                   this.mensajeAlertaModals('success','Se cargaron los datos correctamente.')
            }).finally(()=>{
                this.isLoading=false
                this.dialogFolios=false
            })  
      }else{
         this.mensajeAlertaModals('error','Es necesario adjuntar un documento para continuar.')
             this.isLoading=false
      }

      },
      deleteItemConfirm () {
        this.campanas.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

    cancelarGurdadoCampana(){
        this.dialogCampana = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
    },
    verFolios(item){
      this.dialogFoliosCampana = true

      Servicio.getFolioEstudiosCampana(item.id,this.modelOrigen.id).then((resp)=>{   
        this.itemFoliosCampana = resp.result
      }).finally(()=>{
        this.isLoading=false
      })
    },
    addBotonCampana(){ 
      if(this.botonesCampana.length<3 && this.btnCampana.length>3){
          this.botonesCampana.push(this.btnCampana)
      } 
      
    },
    seleccionAsistente(event) {
      console.log(event)  
      this.cargarCampanas()
    },
    deleteThisButon(btn){
      this.botonesCampana.splice(btn, 1)
    },
    save () { 
        this.ocultarAlerta=false 
       this.editedItem.vigencia_inicio = this.dateInicio2
       this.editedItem.vigencia_fin =this.dateFin2 
       this.editedItem.origen = this.modelOrigen.id
  
       if(this.editedItem.id>0){
             Servicio.actualizarCampana(this.editedItem).then((resp)=>{ 
                if(resp.status){
                    this.ocultarAlerta=true 
                    this.dialog = false
                    this.cargarCampanas()
                    this.enviado=true
                }
                
            }).finally(()=>{
                this.isLoading=false
            }) 
        }else{

            
             Servicio.nuevaCampana(this.editedItem).then((resp)=>{ 
                if(resp.status){
                    this.ocultarAlerta=true 
                    this.dialog = false
                    this.cargarCampanas()
                    this.enviado=true
                }
                
            }).finally(()=>{
                this.isLoading=false
            }) 
            
        }
        
        
      },
  }
}
</script>